const isDeveloperMode = process.env.NODE_ENV !== 'production';
const url = isDeveloperMode ? 'http://localhost:8061' : 'https://api.marketplace.super-appz.ru';

const urlAssets		= 'https://s3.super-appz.ru/download'

const API = {
	url			: `${url}/api/v1/`,
	assets		: `${urlAssets}/marketplace/`,
	version		: '1.0.0'
};

const SETTINGS = {
	DELIVERY_TIME	: 7,
	DELIVERY_PRICE	: 500,
	TIME_COOK		: 30
};

const TIMERS = {
	NEW_ORDERS				: 60 * 1000,
	NEW_ORDERS_SOUND		: 4 * 1000,
	NEW_ORDERS_SOUND_REPEAT	: 5
};

const commonStatus = {
	IN_ACTIVE	: 0,
	ACTIVE		: 1
};
const commonStatusName = ['Не активен','Активен'];

const userStatus = {
	ACTIVE		: 1,
	IN_ACTIVE	: 2,
	BAN			: 3,
	DELETED		: 4
};
const userStatusName = ['','Активен','Не активен','Заблокирован','Удален'];

const orderPartsStatus = {
	UNKNOWN				: 0,
	CREATE				: 1,
	ACCEPT				: 2,
	COLLECT				: 3,
	COLLECT_FINISH		: 4,
	MOVE_TO_DELIVERY	: 5,
	DELIVERY_WAIT		: 6,
	DELIVERY			: 7,
	BUYER_WAIT			: 8,
	SELLER_COMPLETE		: 9,
	COMPLETE			: 10,
	CANCEL				: 11,
	REFUND				: 12
}
const orderPartsStatusName = ['','Создан','Принят','Собирается','Сборка завершена','Передан в доставку','Ожидание доставки','В пути','Ожидает в пункте выдачи','Продавец сообщил о доставке','Получено','Отменен','Возвращен'];

const orderStatus = {
	UNKNOWN		: 0,
	CREATE		: 1,
	PAID		: 2,
	IN_WORK		: 3,
	COMPLETE	: 4,
	CANCEL		: 5,
	DECLINE		: 6
};
const orderStatusName = ['Неизвестно','Создан','Оплачен','В работе','Завершен','Отменен','Отклонен'];

const paymentType = {
	UNKNOWN		: 0,
	CASH		: 1,
	CARD		: 2
};
const paymentTypeName = ['','Наличные','Картой онлайн'];

export {
	API,
	SETTINGS,
	TIMERS,
	commonStatus,
	commonStatusName,
	userStatus,
	userStatusName,
	orderStatus,
	orderStatusName,
	orderPartsStatus,
	orderPartsStatusName,
	paymentType,
	paymentTypeName
};