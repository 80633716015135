import Http from '../../Globals/Http';

const login		= async (username, password)	=> await Http.request('partner/login', {username,password});

const update	= async (id, data)				=> await Http.request(`partner/update/${id}`, data);

const photoAdd	= async (id, type, data)		=> await Http.request(`partner/photo/${type}/${id}`, data, true);


export {
	login,
	update,
	photoAdd
}