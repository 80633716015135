import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// components
import CatalogAdd from '../../Components/CatalogAdd';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import { API } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import closeMenuIcon from '../../Assets/Images/close.svg';

const CatalogScreen = (props) => {
	const [id, setId] = useState(props.match.params.id||null);
	const [point, setPoint] = useState(null);
	const [categories, setCategories] = useState(null);
	const [products, setProducts] = useState(null);
	const [items, setItems] = useState(null);
	const [itemId, setItemId] = useState(null);
	const [loading, setLoading] = useState(true);
	useEffect(async () => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			setPoint(point);
			const catalog = point.catalog;
			catalog.sort((a,b) => a.id > b.id ? 1 : -1);
			const categories = [];
			catalog.forEach((v) => {
				if (!categories.includes(v.category)) categories.push(v.category);
			});
			setCategories(categories);
			const products = catalog.filter(f => categories.find(s => s === f.category) !== undefined).reduce((r, a) => {
				const id = a.category;
				r[id] = [...r[id] || [], a];
				return r;
			}, {});
			setProducts(products);
			if (id !== null) {
				const product = products[categories[id]];
				if (product)
					setItems(dataSet(point, product));
			}
			setLoading(false);
		};
		dataGet();
	}, []);
	const categorySelect = (index, category) => {
		const items = products[category];
		if (items) {
			setId(index);
			setItems(dataSet(point, items));
		} else setId(null);
		return false;
	}
	const countGet = (category) => {
		const items = products[category];
		return items ? `${items.length} шт.` : '0'
	}
	const dataSet = (point, data) => {
		data.forEach((v) => v.image = `${API.assets}${point.partnerId}/${point._id}/images/${v.catalogId}.jpeg`);
		return dgDataPrepare(data, 'catalog', ['catalogId','image','name','weight','price']);
	}
	const showItem = (id) => {
		setItemId(id || 0);
		return false;
	}
	return (
		<TemplateMenu>
			{loading ? <Loader /> :
				<div className="menu-block">
					<div className="categories">
						<h4>Категории</h4>
						<div className="categories-items">
							{categories.length ?
								<ul>
									{categories.map((v,i) => <li key={i} className={id==i?'active':null}>
										<Link to={`/catalog/${i}`} onClick={() => categorySelect(i, v)}>
											{v}
											<div>Позиций: {countGet(v)}</div>
										</Link>
									</li>)}
								</ul>
								: <ul>
									<li className="empty">Категрии еще не заведены</li>
								</ul>
							}
						</div>
					</div>
					<div className="catalog-items">
						{id === null ?
								<div className="empty">
									<h4>Выберите категорию</h4>
									<Link to={'#'} onClick={() => showItem()}>Добавить</Link>
								</div>
							:
								<DataGrid
									title={'Меню'} 
									keys={['Фото блюда','Название','Вес, г', 'Цена, ₽']}
									linkComponent={(id, name) => <Link to={'#'} onClick={() => showItem(id)}>{name}</Link>}
									data={items}
									dataFull={items}
									controlAdd={<Link to={'#'} onClick={() => showItem()}>Добавить</Link>}
									notFound={<NotFound />} />
						}
					</div>
				</div>
			}
			{itemId !== null ?
				<>
					<div className="overlay"></div>
					<div className="right-side-panel">
						<CatalogAdd id={itemId} categoryId={id?categories[id]:null} />
						<div className="modal-menu-close" onClick={() => setItemId(null)}>
							<img src={closeMenuIcon} alt=""/>
						</div>
					</div>
				</> : null}
		</TemplateMenu>
	);
};

export default CatalogScreen;