import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import Moment from 'moment';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';
import Alert,{alertShow} from '../../Components/Alert';

// models
import {Orders} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {moneyFormat,orderNumberGet,phoneFormatter} from '../../Globals/Utils';

// globals
import {paymentTypeName,orderPartsStatus,orderPartsStatusName,API} from '../../Globals/Constants';

// styles
import './styles.css';

// images
import noOrdersImage from '../../Assets/Images/noorders.svg';

const OrdersScreen = (props) => {
	const [id, setId] = useState(props.match.params.id||null);
	const [user, setUser] = useState(null);
	const [partner, setPartner] = useState(null);
	const [orders, setOrders] = useState(null);
	const [order, setOrder] = useState(null);
	const [parts, setParts] = useState(null);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const dataGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const partner = user;
			setUser(user);
			setPartner(partner);
			await ordersGet(partner);
			setLoading(false);
		};
		dataGet();
	}, []);
	const ordersGet = async (partner) => {
		const orders = await Orders.getActive(partner._id);
		orders.sort((a,b) => Moment(a.createdAt).unix() > Moment(b.createdAt).unix() ? -1 : 1);
		setOrders(orders);
		if (id !== null) {
			const order = orders.find(f => f._id === id);
			if (order) {
				setOrder(order);
				setParts(order.parts[0]);
			}
		}
		return orders;
	}
	const orderSelect = (order) => {
		const orders = ls('orders') || [];
		orders.forEach((v) => {
			if (v.id === order._id)
				v.isnew = false;
		});
		ls('orders', orders);
		setOrder(order);
		setParts(order.parts[0]);
		setId(order._id);
		return false;
	}
	const isNewOrder = (id) => {
		const orders = ls('orders') || [];
		return orders.find(f => f.id === id)?.isnew;
	}
	const button = () => {
		let text = '', status = orderPartsStatus.UNKNOWN;
		const diff = Moment.duration(Moment(order.createdAt).add(parts.deliveryDate, 'day').diff(Moment()));
		let last = `осталось ${parseInt(diff.asDays())} д.`;
		if (diff.asDays() < 1) last = `осталось ${parseInt(diff.asHours())} ч.`;
		if (diff.asDays() < 1) last = 'задержка';
		const right = <div>{last}</div>;
		switch (parts.status) {
			case orderPartsStatus.CREATE:
				text = 'Принять заказ';
				status = orderPartsStatus.ACCEPT;
				break;
			case orderPartsStatus.ACCEPT:
				text = 'Начать сборку';
				status = orderPartsStatus.COLLECT;
				break;
			case orderPartsStatus.COLLECT:
				text = 'Сборка завершена';
				status = orderPartsStatus.COLLECT_FINISH;
				break;
			case orderPartsStatus.COLLECT_FINISH:
				text = 'Передать в доставку';
				status = orderPartsStatus.MOVE_TO_DELIVERY;
				break;
			case orderPartsStatus.MOVE_TO_DELIVERY:
				text = 'Доставляется';
				break;
			case orderPartsStatus.BUYER_WAIT:
				if (order.delivery) {
					text = 'Сообщить о доставке';
					status = orderPartsStatus.SELLER_COMPLETE;
				} else return;
				break;
			default: return;
		}
		return <button type="button" onClick={() => save(status)}>
			<div>{moneyFormat(totalCalc(order))} ₽</div>
			{text}
			{right}
		</button>;
	}
	const buttoncancel = () => parts.status === orderPartsStatus.CREATE ? <button type="button" className="button-cancel" onClick={() => save(orderPartsStatus.CANCEL)}>Отменить</button> : null;
	const save = async (status) => {
		if (status === orderPartsStatus.UNKNOWN) return;
		await Orders.update(partner._id, order._id, {status});
		order.parts[0].status = status;
		parts.status = status;
		setOrder({...order});
		setParts({...parts});
		if (status === orderPartsStatus.CANCEL) {
			ordersGet(partner);
			alertShow('Заказ отменен');
			return;
		}
		alertShow('Заказ обновлен');
	}
	const orderStyle = (order) => {
		const style = [];
		if (id == order._id) style.push('active');
		if (isNewOrder(order._id)) style.push('alert-new');
		return style.join(' ');
	}
	const totalCalc = (order) => order.parts[0].products.reduce((a, v) => a + (v.priceDiscount||v.price) * v.quantity, 0);
	return (
		<TemplateMenu>
			{loading ? <Loader /> :
				(orders && orders.length ?
						<div className="menu-block">
							<div className="orders">
								<h4>Заказы</h4>
								<div className="orders-items">
									<ul>
										{orders.map((v,i) => <li key={i} className={orderStyle(v)}>
											<Link to={`/orders/${v._id}`} onClick={() => orderSelect(v)}>
												<b>{moneyFormat(totalCalc(v))} ₽</b>
												<div>№ {orderNumberGet(v.paymentId)}</div>
												{v.point ? null : <div>Доставка курьером • {moneyFormat(v.deliveryPrice)} ₽</div>}
												{isNewOrder(v._id) ? <div className="order-new"></div> : null}
											</Link>
										</li>)}
									</ul>
								</div>
							</div>
							<div className="order-info">
								{order ?
										<>
											<div className="order-info-inner">
												<div className="block">
													<div className="row">
														<div>Статус</div>
														<div>{orderPartsStatusName[parts.status]}</div>
													</div>
													<div className="row">
														<div>Номер заказа</div>
														<div><b>{orderNumberGet(order.paymentId)}</b></div>
													</div>
													<div className="row">
														<div>Принято</div>
														<div>{Moment(order.createdAt).format('DD.MM.YYYY HH:mm')}</div>
													</div>
													<div className="row">
														<div>Доставка</div>
														<div>{Moment(order.createdAt).add(parts.deliveryDate, 'day').format('DD.MM.YYYY')}</div>
													</div>
													<div className="row">
														<div>Город доствки</div>
														<div>г. {order.address.city}</div>
													</div>
													{order.point ?
															<div className="row">
																<div>Доставка в ПВЗ</div>
																<div>г. {order.point.cityName}, {order.point.address}</div>
															</div>
														:
															<div className="row">
																<div>Доставка курьером</div>
																<div>{moneyFormat(order.deliveryPrice)} ₽</div>
															</div>
													}
													{order.parts[0].cargo ?
														<>
															<div className="row">
																<div>Перевозчик</div>
																<div>{order.parts[0].cargo.name}</div>
															</div>
															<div className="row">
																<div>Телефон перевозчика</div>
																<div><a href={`tel:${order.parts[0].cargo.phone}`}>{phoneFormatter(order.parts[0].cargo.phone)}</a></div>
															</div>
														</> : null}
													<div className="row">
														<div>Оплата</div>
														<div>{paymentTypeName[order.paymentType]}</div>
													</div>
													<div className="row">
														<div>Заказ оплечен</div>
														<div>{order.isPaid ? 'Да' : 'Нет'}</div>
													</div>
													<div className="row">
														<div>Клиент</div>
														<div>{order.userFullName}</div>
													</div>
													<div className="row">
														<div>Телефон клиента</div>
														<div><a href={`tel:${order.userPhone}`}>{phoneFormatter(order.userPhone)}</a></div>
													</div>
													<div className="row">
														<div>Комментарий</div>
														<div>{parts.comment || 'Нет комментария'}</div>
													</div>
												</div>
												<div className="block">
													<h4>Состав заказа <span>позиций: {order.parts[0].products.length} шт</span></h4>
													{order.parts[0].products.map((v,i) => <div key={i} className="row-check">
														<div>
															<div>
																<span className="item-category">{v.category}</span>
																{v.quantity} × <span className="item-name">{v.name}</span> {v.weight ? <span className="item-weight">{v.weight} кг</span> : null}
															</div>
															<a href={`${API.url}order/qrcode/${order._id}/${v._id}/${order.point?order.point._id:0}`} target="_blank">показать QR-код товара</a>
														</div>
														<div>{v.quantity ? `${v.quantity} × ` : ''}{moneyFormat(v.price)} ₽</div>
													</div>)}
													<div className="row-total">
														<div><b>Итого</b></div>
														<div><b>{moneyFormat(totalCalc(order))} ₽</b></div>
													</div>
												</div>
											</div>
											<div className="buttons">
												{button()}
												{buttoncancel()}
											</div>
										</>
									:
										<div className="not-selected">
											<h4>Заказ не выбран</h4>
											<p>Воспользуйтесь списоком слева, чтобы посмотреть подробную ифнормацию о заказе</p>
										</div>
								}
							</div>
						</div>
					:
						<div className="orders-empty">
							<div>
								<img src={noOrdersImage} alt="" />
								<h4>Заказов нет</h4>
							</div>
						</div>
				)
			}
			<Alert />
		</TemplateMenu>
	);
};

export default OrdersScreen;