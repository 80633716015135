import React, {useEffect,useState} from 'react';

// plug-ins
import Moment from 'moment';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import NotFound from '../../Components/NotFound';

// models
import {Orders} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {moneyFormat,orderNumberGet,phoneFormatter} from '../../Globals/Utils';

// globals
import {orderPartsStatusName,paymentTypeName} from '../../Globals/Constants';

// styles
import './styles.css';

const OrderScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const [order, setOrder] = useState(null);
	useEffect(() => {
		const orderGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const partner = user;
			const order = await Orders.getById(partner._id, id).catch((ex) => console.log(666, ex));
			setOrder(order);
			setLoading(false);
		};
		orderGet();
	}, []);
	const totalCalc = (order) => order.parts.products.reduce((a, v) => a + (v.priceDiscount||v.price) * v.quantity, 0);
	return (
		<TemplateMenu>
			{loading ? <Loader /> :
				<div>
					<Back title="Иформаиция о заказе" link='histories' />
					{order === null ? <NotFound /> :
						<>
							<div className="form-container">
								<div className="cell">
									<label>
										<span>Номер заказа</span>
										<div>№ <b>{orderNumberGet(order.paymentId)}</b></div>
									</label>
								</div>
								<div className="cell-oneline">
									<div className="cell">
										<label>
											<span>Статус</span>
											<div>{orderPartsStatusName[order.parts.status]}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Принято</span>
											<div>в {Moment(order.createdAt).format('HH:mm, DD.MM.YYYY')}</div>
										</label>
									</div>
								</div>
								<div className="cell-oneline">
									<div className="cell">
										<label>
											<span>Сумма заказа</span>
											<div>{moneyFormat(totalCalc(order))} ₽</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Доставка</span>
											<div>{moneyFormat(order.deliveryPrice)} ₽</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Оплата</span>
											<div>{paymentTypeName[order.paymentType]}</div>
										</label>
									</div>
								</div>
								<div className="cell-oneline">
									<div className="cell">
										<label>
											<span>Клиент</span>
											<div>{order.userFullName}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Телефон клиента</span>
											<div>{phoneFormatter(`${order.userPhone}`)}</div>
										</label>
									</div>
								</div>
								<div className="cell">
									<label>
										<span>Комментарий</span>
										<div>{order.comment || 'Нет комментария'}</div>
									</label>
								</div>
							</div>
							<div className="form-container">
								<h4>Состав заказа</h4>
								{order.parts.products.map((v,i) => <div key={i} className="row">
									<div>
										<span className="category">{v.category}</span>
										<span className="item-name">{v.name}</span> {v.weight ? <span className="item-weight">{v.weight} г.</span> : null}
									</div>
									<div>{v.quantity ? `${v.quantity} × ` : ''}{moneyFormat(v.price)} ₽</div>
								</div>)}
								<div className="row-total">
									<div><b>Итого</b></div>
									<div><b>{moneyFormat(order.price)} ₽</b></div>
								</div>
							</div>
						</>
					}
				</div>
			}
		</TemplateMenu>
	);
};

export default OrderScreen;