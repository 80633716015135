import React, {useEffect,useState} from 'react';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';
import {useInput} from '../../Components/Hooks/Input';
import NotFound from '../../Components/NotFound';

// models
import {Partners} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import {commonStatus,commonStatusName,SETTINGS} from '../../Globals/Constants';

// styles
import './styles.css';

const MarketEditScreen = () => {
	const [loading, setLoading] = useState(true);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:info, setValue:setInfo, bind:bindInfo} = useInput('');
	const [rating, setRating] = useState(0);
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:email, setValue:setEmail, bind:bindEmail} = useInput('');
	const [status, setStatus] = useState(commonStatus.ACTIVE);
	const [partner, setPartner] = useState(null);
	useEffect(async () => {
		const user = ls('user');
		if (!user) {
			window.location.href = '/login';
			return;
		}
		const partnerGet = async () => {
			const user = ls('user');
			const partner = user;
			partnerDataFill(partner);
			setPartner(partner);
		};
		const partnerDataFill = (partner) => {
			setName(partner.name);
			setDescription(partner.description);
			setInfo(partner.info);
			setRating(partner.rating);
			setPhone(partner.phone);
			setEmail(partner.email);			
			setStatus(partner.status);
		};
		await partnerGet();
		setLoading(false);
	}, []);
	const selectHandleStatus = (e) => setStatus(parseInt(e.target.value));
	const save = async (e) => {
		e.preventDefault();
		const data = {
			name,
			description,
			info,
			phone,
			email,
			status
		};
		try {
			await Partners.update(partner._id, data);
			alertShow('Данные обновлены');
			setTimeout(() => window.location.href = '/market', 2000);
		} catch (ex) {
			console.log(666, ex);
			alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
	}
	const page = loading ? <Loader /> :
		<form onSubmit={save}>
			<Back title={'Редактирование магазина'} link={'market'} />
			{partner === null ? <NotFound /> :
				<>
					<div className="form-container form-container-large">
						<div className="cell">
							<label>
								<span>Название</span>
								<input type="text" {...bindName} placeholder="Название, описание" className='double' required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Подробное описание</span>
								<textarea {...bindDescription} className="desc" placeholder="Подробное описание" required></textarea>
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Информация по возвратам</span>
								<textarea {...bindInfo} className="desc" placeholder="Информация по возвратам" required></textarea>
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Телефон</span>
								<input type="text" {...bindPhone} placeholder="" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Электронная почта</span>
								<input type="text" {...bindEmail} placeholder="" required />
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Рейтинг</span>
								<div>★ {rating}</div>
							</label>
						</div>
						<div className="cell-online cell-online-start">
							<div className="cell">
								<label>
									<span>Максимальное время доставки</span>
									<div>{SETTINGS.DELIVERY_TIME} дней</div>
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Цена доставки курьером</span>
									<div>{SETTINGS.DELIVERY_PRICE } ₽</div>
								</label>
							</div>
						</div>
						<div className="cell">
							<label>
								<span>Статус</span>
								<select className="select-minimal" onChange={selectHandleStatus} value={status}>
									{commonStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
								</select>
							</label>
						</div>
						<div className="buttons">
							<button type="submit">Сохранить</button>
						</div>
					</div>
				</>
			}	
		</form>;
	return (
		<TemplateMenu>
			{page}
			<Alert />
		</TemplateMenu>
	);
};

export default MarketEditScreen;