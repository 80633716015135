import React, {useEffect,useState} from 'react';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';

// models
import {Partners} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import {API} from '../../Globals/Constants';

// styles
import './styles.css';

const MarketPhotoHeaderScreen = () => {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [partner, setPartner] = useState(null);
	const [image, setImage] = useState(null);
	useEffect(async () => {
		const pointGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const partner = user;
			setUser(user);
			setPartner(partner);
			setImage({url:`${API.assets}/sellers/${partner.code}/header.jpg`});
		};
		pointGet();
		setLoading(false);
	}, []);
	const save = async (e) => {
		e.preventDefault();
		if (image === null) {
			alertShow('<b>Ошибка!</b><br/>Необходимо добавить фотографию заставки магазина', true);
			return;
		}
		try {
			await imageAdd();
			alertShow('Данные обновлены');
		} catch (ex) {
			alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
	}
	const imageChange = (e) => {
		const reader = new FileReader(), file = e.target.files[0];
		reader.onloadend = () => setImage({file,data:reader.result});
		reader.readAsDataURL(file);
	}
	const imageAdd = async () => {
		if (image.file) {
			const fd = new FormData();
			fd.append('image', image.file);
			await Partners.photoAdd(user._id, 'header', fd);
		}
	}
	return (
		<TemplateMenu>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={'Фотография заставки магазина'} link='market' />
					<div className="form-container">
						<h4>Заставка магазина в заголовке</h4>
						<div className="room-photos">
							{image === null ? null :
								<label className="room-photo">
									<img src={image.url ? image.url : image.data} alt="" />;
								</label>
							}
							<label className="room-photo room-photo-empty">
								<div className="photo-add">+</div>
								<input onChange={imageChange} name="image" className="file" accept="image/jpeg" type="file" />
							</label>
						</div>
						<div className="buttons">
							<button type="submit">Сохранить</button>
						</div>
					</div>
				</form>
			}
			<Alert />
		</TemplateMenu>
	);
};

export default MarketPhotoHeaderScreen;