import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import {useModal} from 'react-hooks-use-modal';

// models
import { Orders } from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import { API, TIMERS } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import menuIcon from '../../Assets/Images/menu.icon.svg';
import closeMenuIcon from '../../Assets/Images/close.svg';

// audio
import newOrderSound from '../../Assets/Audio/new-order.mp3';

const TemplateMenu = (props) => {
	const [Modal, open, close] = useModal('root', {preventScroll:true});
	const [user, setUser] = useState(null);
	const [partner, setPartner] = useState(null);
	const [audio] = useState(new Audio(newOrderSound));
	const [isAudioPlay, setIsAudioPlay] = useState(false);
	const [orders, setOrders] = useState([]);
	const [isApiCall, setIsApiCall] = useState(false);
	const done = () => {
		ls();
		close();
		window.location.href='/login';
	}
	const show = (e) => {
		e.preventDefault();
		open();
	}
	const hide = (e) => {
		e.preventDefault();
		close();
	}
	const openMenu = (e) => {
		let menu = document.querySelector('.menu');
		menu.classList.add('active')
	}
	const closeMenu = (e) => {
		let menu = document.querySelector('.menu');
		menu.classList.remove('active')
	}
	useEffect(() => {
		const user = ls('user');
		if (user === null) {
			window.location.href='/error403';
			return;
		}
		setUser(user);
		const partner = user;
		setPartner(partner);
		const ordersGet = async () => {
			const o = await Orders.getActive(partner._id);
			setOrders(o);
		};
		setIsApiCall(true);
		ordersGet();
		setInterval(ordersGet, TIMERS.NEW_ORDERS);
	}, []);
	useEffect(() => {
		if (!isApiCall) return;
		const old = ls('orders') || [];
		console.log('old', old)
		const o = [];
		let isnew = false;
		orders.forEach((v) => {
			const order = old.find(f => f.id === v._id);
			if (order) o.push(order);
			else {
				isnew = true;
				o.push({id:v._id,isnew});
			}
		});
		if (isnew) soundPlay();
		ls('orders', o)
	}, [orders]);
	const soundPlay = () => {
		console.log('is play', isAudioPlay)
		if (isAudioPlay) return;
		let tid, count = 0;
		tid = setInterval(() => {
			audio.play();
			console.log('cnt', count)
			if (count > TIMERS.NEW_ORDERS_SOUND_REPEAT) {
				console.log('stop', count)
				clearInterval(tid);
				tid = null;
				setIsAudioPlay(false);
				return;
			}
			count++;
		}, TIMERS.NEW_ORDERS_SOUND);
		setIsAudioPlay(true);
	}
	const isneworder = () => {
		const orders = ls('orders') || [];
		return orders.find(f => f.isnew === true);
	}
	const isselect = (page) => window.location.pathname.indexOf(page) !== -1 ? 'selected' : null;
	return (
		<div className="container">
			<div className="menu-open" onClick={openMenu}>
				<img src={menuIcon} alt="menu-icon"/>
			</div>
			<div className="menu">
				<div className="menu-close" onClick={closeMenu}>
					<img src={closeMenuIcon} alt="" />
				</div>
				<ul>
					{partner ?
						<li className="partner-info">
							<img src={`${API.assets}sellers/${partner.code}/${partner.code}.png`} className="partner-photo" alt="" />
							<div className="partner-name">{partner.name}</div>
						</li> : null}
					<li><Link to={'/dashboard'} className={isselect('dashboard')}>Главная</Link></li>
					<li><Link to={'/market'} className={isselect('market')}>Магазин</Link></li>
					{/* <li><b>Каталог</b></li>
					<li><Link to={'/products'} className={isselect('product')}>Товары</Link></li> */}
					<li><b>Заказы{isneworder() ? <span className="new-order"></span> : null}</b></li>
					<li><Link to={'/orders'} className={isselect('order')}>Список заказов</Link></li>
					<li><Link to={'/histories'} className={isselect('histor')}>История заказов</Link></li>
				</ul>
				<div className="logoff">
					<Link to={'/'} onClick={show}>Выход из системы</Link>
				</div>
			</div>
			<div className="main">
				{props.children}
			</div>
			<Modal>
				<div className="confirmcontainer">
					<h4>Подтвердите пожалуйста</h4>
					Вы уверены что хотите выйти из системы администрирования?
					<div className="buttons">
						<button onClick={done}>Да</button>
						<Link to={'/'} className="cancel" onClick={hide}>Отмена</Link>
					</div>
				</div>
			</Modal>
		</div>
	);
};
export default TemplateMenu;