import React, {useEffect,useState} from 'react';

// components
import Loader from '../Loader';
import Alert,{alertShow} from '..//Alert';
import {useInput} from '../Hooks/Input';
import NotFound from '..//NotFound';

// models
import {Catalog,Categories,Partners} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';

// globals
import { API } from '../../Globals/Constants';

// styles
import './styles.css';

const CatalogAdd = (props) => {
	const [loading, setLoading] = useState(true);
	const [id, setId] = useState(props.id||0);
	const [categoryId, setCategoryId] = useState(props.categoryId||0);
	const [user, setUser] = useState(null);
	const [item, setItem] = useState(null);
	const [point, setPoint] = useState(null);
	const [categories, setCategories] = useState(null);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:ingredients, setValue:setIngredients, bind:bindIngredients} = useInput('');
	const {value:price, setValue:setPrice, bind:bindPrice} = useInput('');
	const {value:weight, setValue:setWeight, bind:bindWeight} = useInput('');
	const [image, setImage] = useState(null);
	useEffect(async () => {
		const dataGet = async () => {
			const point = ls('point');
			if (!point) {
				window.location.href = '/points';
				return;
			}
			setUser(ls('user'));
			const catalogId = parseInt(id);
			const item = id === 0 ? null : point.catalog.find((v) => v.catalogId === catalogId);
			if (item) {
				setName(item.name);
				setIngredients(item.ingredients);
				setPrice(item.price);
				setWeight(item.weight);
				setCategoryId(item.category);
				setImage({url:`${API.assets}${point.partnerId}/${point._id}/images/${item.catalogId}.jpeg`});
			}
			setPoint(point);
			setItem(item);
			const categories = await Categories.get();
			setCategories(categories);
		};
		await dataGet();
		setLoading(false);
	}, [id]);
	const selectHandle = (e) => setCategoryId(e.target.value);
	const save = async (e) => {
		e.preventDefault();
		let ing = ingredients.trim().replaceAll('\r\n', ', ').replaceAll('\r', ', ').replaceAll('\n', ', ');
		const data = {
			name,
			category:categoryId,
			price,
			weight,
			ingredients:ing
		};
		if (image === null) {
			alertShow('<b>Ошибка!</b><br/>Необходимо добавить фотографию блюда', true);
			return;
		}
		try {
			if (id === 0) {
				const res = await Catalog.add(user._id, point._id, data);
				await imageAdd(res.id);
				alertShow('Позиция добавлена');
				setId(res.id)
			} else {
				await Catalog.update(user._id, point._id, id, data);
				await imageAdd(id);
				alertShow('Данные обновлены');
			}
			// const p = await Points.getById(point._id);
			// ls('point', p);
			if (id === 0)
				setTimeout(() => window.location.href = '/catalog', 2000);
		} catch (ex) {
			alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
	}
	const imageChange = (e) => {
		const reader = new FileReader(), file = e.target.files[0];
		reader.onloadend = () => setImage({file,data:reader.result});
		reader.readAsDataURL(file);
	}
	const imageAdd = async (id) => {
		if (image.file) {
			const fd = new FormData();
			fd.append('image', image.file);
			await Catalog.photoAdd(user._id, point._id, id, fd);
		}
	}
	return (
		<>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<h4>{id === 0 ? 'Добавить позицию' : 'Редактирование позиции'}</h4>
					{id && item === null ? <NotFound /> :
						<>
							<div className="form-container">
								<div className="cell">
									<label>
										<span>Название</span>
										<input type="text" {...bindName} placeholder="Название, описание" className='double' required />
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Состав</span>
										<textarea {...bindIngredients} className="desc" placeholder="Подробное описание" required></textarea>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Цена, ₽</span>
										<input type="text" {...bindPrice} placeholder="" required />
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Вес, г</span>
										<input type="text" {...bindWeight} placeholder="" required />
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Категория блюда</span>
										<select className="select-minimal" onChange={selectHandle} value={categoryId}>
											{categories.map((v,i) => <option key={i} value={v.name}>{v.name}</option>)}
										</select>
									</label>
								</div>
							</div>
							<div className="form-container form-container-large">
								<h4>Фотография блюда</h4>
								<div className="room-photos">
									{image === null ? null :
										<label className="room-photo">
											<img src={image.url ? image.url : image.data} alt="" onError={(e) => setImage(null)} />;
										</label>
									}
									<label className="room-photo room-photo-empty">
										<div className="photo-add">+</div>
										<input onChange={imageChange} name="image" className="file" accept="image/jpeg" type="file" />
									</label>
								</div>
								<div className="buttons-modal">
									<button type="submit">Сохранить</button>
								</div>
							</div>
						</>
					}
				</form>
			}
			<Alert />
		</>
	);
};

export default CatalogAdd;