import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// plug-ins
import Moment from 'moment';

// components
import TemplateMenu from '../../Components/TemplateMenu';
import Loader from '../../Components/Loader';

// models
import { PartnerAccounts, Settings } from '../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import { phoneFormatter } from '../../Globals/Utils';

// globals
import { API, SETTINGS } from '../../Globals/Constants';

// styles
import './styles.css';

// images
import iconEdit from '../../Assets/Images/edit.svg';

const MarketScreen = () => {
	const [partner, setPartner] = useState(null);
	const [imageHeader, setImageHeader] = useState(null);
	const [imageLogo, setImageLogo] = useState(null);
	const [account, setAccount] = useState(null);
	const [comission, setComission] = useState(null);
	const [comissionDelivery, setComissionDelivery] = useState(null);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const marketGet = async () => {
			const user = ls('user');
			if (user === null) {
				window.location.href = '/login';
				return;
			}
			const partner = user;
			setPartner(partner);
			setImageHeader(`${API.assets}sellers/${partner.code}/header.jpg`);
			setImageLogo(`${API.assets}sellers/${partner.code}/${partner.code}.png`);
			const settings = ls('settings');
			const comission = Settings.getById('partner-comission', settings);
			const comissionDelivery = Settings.getById('partner-last-mile-comission', settings);
			setComission(comission);
			setComissionDelivery(comissionDelivery);
			const account = await PartnerAccounts.get();
			setAccount(account);
			setLoading(false);
		};
		marketGet();
	}, []);
	const rn2br = (text) => text.split('\n').map((v,i) => <p key={i}>{v}</p>);
	return (
		<TemplateMenu>
			{loading ? <Loader /> :
				<form onSubmit={() => {}}>
					<h4 className="no-back-title">Информация по магазину</h4>
					<div className="form-container">
						<h4 className="subtitle-control">
							Общая информация
							<Link to={'/market-edit'}><img src={iconEdit} alt="" /></Link>
						</h4>
						<div className="cell-online cell-online-start">
							<div className="cell">
								<label>
									<span>Название</span>
									<div>{partner.name}</div>
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Рейтинг</span>
									<div>★ {partner.rating}</div>
								</label>
							</div>
						</div>
						<div className="cell-online cell-online-start">
							<div className="cell">
								<label>
									<span>Город</span>
									<div>{partner.cityName}</div>
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Адрес</span>
									<div>{account.address}</div>
								</label>
							</div>
						</div>
						<div className="cell-online cell-online-start">
							<div className="cell">
								<label>
									<span>Телефон</span>
									<div>{phoneFormatter(partner.phone)}</div>
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Телефон</span>
									<div>{partner.email}</div>
								</label>
							</div>							
						</div>
						<div className="cell-online cell-online-start">
							<div className="cell">
								<label>
									<span>Максимальное время доставки</span>
									<div>{SETTINGS.DELIVERY_TIME} дней</div>
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Цена доставки курьером</span>
									<div>{SETTINGS.DELIVERY_PRICE } ₽</div>
								</label>
							</div>
						</div>
						<div className="cell">
							<label>
								<span>Описание</span>
								<div>{rn2br(partner.description || 'Не задано')}</div>
							</label>
						</div>
						<div className="cell">
							<label>
								<span>Информация по возвратам</span>
								<div>{rn2br(partner.info)}</div>
							</label>
						</div>
					</div>
					<div className="form-container">
						<h4 className="subtitle-control">
							Фотография заставки магазина
							<Link to={'/market-edit-photo/header'}><img src={iconEdit} alt="" /></Link>
						</h4>
						<div className="partner-photos">
							{imageHeader ?
								<div className="partner-header-photo">
									<img src={imageHeader} alt="" />
								</div> : null}
						</div>
					</div>
					<div className="form-container">
						<h4 className="subtitle-control">
							Фотография логотипа магазина
							<Link to={'/market-edit-photo/logo'}><img src={iconEdit} alt="" /></Link>
						</h4>
						<div className="partner-photos">
							{imageLogo ?
								<div className="partner-logo-photo">
									<img src={imageLogo} alt="" />
								</div> : null}
						</div>
					</div>
					<div className="form-container">
						<h4>Бухгалтерская информация</h4>
						{account ?
							<>
								<div className="cell-online cell-online-start">
									<div className="cell">
										<label>
											<span>ИНН / КПП</span>
											<div>{account.inn} / {account.kpp}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>БИК</span>
											<div>{account.bik}</div>
										</label>
									</div>
								</div>
								<div className="cell-online cell-online-start">
									<div className="cell">
										<label>
											<span>Расчётный счёт</span>
											<div>{account.account}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Юридическое название</span>
											<div>{account.name}</div>
										</label>
									</div>
								</div>
								<div className="cell-online cell-online-start">
									<div className="cell">
										<label>
											<span>Юридический адрес</span>
											<div>{account.address}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Почтовый адрес</span>
											<div>{account.addressPost}</div>
										</label>
									</div>
								</div>
								<div className="cell-online cell-online-start">
									<div className="cell">
										<label>
											<span>Телефон бухгалтерии</span>
											<div>{phoneFormatter(account.phone)}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Электронная почта</span>
											<div>{account.email}</div>
										</label>
									</div>
								</div>
								<div className="cell-online cell-online-start">
									<div className="cell">
										<label>
											<span>Имя подписанта</span>
											<div>{account.signerName}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Должность подписанта</span>
											<div>{account.signerPosition}</div>
										</label>
									</div>
								</div>
								<div className="cell-online cell-online-start">
									<div className="cell">
										<label>
											<span>Номер договора</span>
											<div>{account.contractNumber}</div>
										</label>
									</div>
									<div className="cell">
										<label>
											<span>Дата начала действия договора</span>
											<div>{Moment(account.contractDate).format('DD.MM.YYYY')}</div>
										</label>
									</div>
								</div>
							</> : <p>Выплаты приостановлены!<br/>Пожалуйста обратитесь в поддержку сервиса для предоставлдения бухгалтерской информации.</p>}
					</div>
					<div className="form-container">
						<h4>Условия работы</h4>
						<div className="cell-online cell-online-start">
							<div className="cell">
								<label>
									<span>Комиссия сервису</span>
									<div>{comission.value} %</div>
								</label>
							</div>
							<div className="cell">
								<label>
									<span>Комиссия за «последнюю милю»</span>
									<div>{comissionDelivery.value} %</div>
								</label>
							</div>
						</div>
					</div>
				</form>
			}
		</TemplateMenu>
	);
};

export default MarketScreen;