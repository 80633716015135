import Http from '../../Globals/Http';

const add		= async (id, pointId, data)			=> await Http.request(`point/catalog/item/add/${id}/${pointId}`, data);
const update	= async (id, pointId, itemId, data)	=> await Http.request(`point/catalog/item/update/${id}/${pointId}/${itemId}`, data);

const remove	= async (id, pointId, itemId)		=> await Http.request(`point/catalog/item/remove/${id}/${pointId}/${itemId}`);

const photoAdd	= async (id, pointId, itemId, data)	=> await Http.request(`point/catalog/item/photo/add/${id}/${pointId}/${itemId}`, data, true);


export {
	add,
	update,
	remove,
	photoAdd
}