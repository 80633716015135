import Http from '../../Globals/Http';

const getAll	= async (id)				=> await Http.request(`orders/${id}`);
const getActive	= async (id)				=> await Http.request(`orders/active/${id}`);
const getFinish	= async (id)				=> await Http.request(`orders/finish/${id}`);

const getById	= async (id, orderId)		=> await Http.request(`order/${id}/${orderId}`);

const update	= async (id, orderId, data)	=> await Http.request(`order/update/${id}/${orderId}`, data);


export {
	getAll,
	getActive,
	getFinish,
	getById,
	update
}